import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
  const handleTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Container>
        <div className="footer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#7bed9f"
              fill-opacity="1"
              d="M0,192L60,208C120,224,240,256,360,256C480,256,600,224,720,181.3C840,139,960,85,1080,58.7C1200,32,1320,32,1380,32L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
          <div className="main-foot">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-12">
                  <div className="foot-data">
                    <div className="ft-logo">
                      <img
                        src="https://res.cloudinary.com/antrix/image/upload/v1701974425/ipo/ipomoney_logo_xr5reo.png"
                        alt="logo"
                        srcset=""
                      />
                      <h3>Header</h3>
                      <p className="footdetails">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quia commodi quis inventore dolorum magnam, fuga magni
                        dicta asperiores veniam repudiandae consequatur adipisci
                        sed porro amet suscipit
                      </p>
                    </div>
                  </div>
                  <div className="widthsett mt-4">
                    <ul className="socialcontact d-flex justify-content-start">
                      <li className="mx-3">
                        <a href="/" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://res.cloudinary.com/antrix/image/upload/v1678714799/facebook-2429746_640_pwgv7r.png"
                            alt=""
                            srcset=""
                            className="socialImagenav"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="/" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://res.cloudinary.com/antrix/image/upload/v1678771526/instagram-1675670_640_qpdp85.png"
                            alt=""
                            srcset=""
                            className="socialImagenav"
                          />
                        </a>
                      </li>
                      <li className="mx-3">
                        <a href="/" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://res.cloudinary.com/antrix/image/upload/v1678771458/twitter-2430933_640_x1s7vm.png"
                            alt=""
                            srcset=""
                            className="socialImagenav"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="/" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://res.cloudinary.com/antrix/image/upload/v1678780890/LinkedIn_icon_circle.svg_eimvkc.png"
                            alt=""
                            srcset=""
                            className="socialImagenav"
                          />
                        </a>
                      </li>
                      <li className="mx-3">
                        <a href="/" target="_blank" rel="noopener noreferrer">
                          <img
                            src="https://res.cloudinary.com/antrix/image/upload/v1702060142/ipo/youtubenih_bzhryu.png"
                            alt=""
                            srcset=""
                            className="socialImagenav"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12">
                  <div className="foot-data">
                    <h3>Useful links</h3>
                    <ul>
                      <li onClick={handleTop}>
                        <Link to="/about">IPO GMP</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/founder">IPO Allotment</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/gallery">Apply IPO</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/contact">IPO listing</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/contact">Main IPO</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12">
                  <div className="foot-data">
                    <h3>Useful links</h3>
                    <ul>
                      <li onClick={handleTop}>
                        <Link to="/about">Learn IPO</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/founder">FAQ</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/gallery">Latest IPO Updates</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/contact">SME IPO</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12">
                  <div className="foot-data">
                    <h3>Useful links</h3>
                    <ul>
                      <li onClick={handleTop}>
                        <Link to="/about">IPO Reviews</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/founder">Advertise with us</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/gallery">About us</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/contact">Careers</Link>
                      </li>
                      <li onClick={handleTop}>
                        <Link to="/contact">IPO Subscription</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="last-foot mt-5 text-center">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                  {/* <Link to="/Terms" onClick={handleTop}>
              <p>Terms & Conditions</p>
            </Link> */}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  {" "}
                  <p className="text-center">
                    Copyright©2023 IPOmoney.in All Right Reserved.
                  </p>{" "}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                  {/* {" "}
            <Link to="/privacy" onClick={handleTop}>
              <p>Privacy Policy</p>
            </Link>{" "} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Footer;
const Container = styled.div`
  .footer {
    .main-foot {
      background-color: #7bed9f;
      height: 100%;
    }
  }

  .foot-data {
    margin-top: 3rem;
    @media screen and (max-width: 500px) {
    }
    h3 {
      color: #3d3f99;
      font-family: "Bricolage Grotesque", sans-serif;
      font-size: 2rem;
      text-shadow: 1px 2px 4px #433f3f;
    }
    ul {
      padding-left: 0rem;
      li {
        list-style: none;
        margin-top: 1rem;
        img {
          box-shadow: 1px 7px 78px #334957;
        }
        a {
          text-decoration: none;
          color: #4244ad;
          font-size: 1.4rem;
          font-family: "Bricolage Grotesque", sans-serif;
        }
        a:hover {
          color: #000;
        }
      }
    }
  }
  .last-foot {
    p {
      text-shadow: rgb(78 190 147) 1px 1px 7px;
      color: #0b100e;
      font-weight: 700;
      font-family: "Bricolage Grotesque", sans-serif;
    }
    p::before {
      content: "";
      background-color: red;
      width: 10rem;
      height: 10rem;
    }
  }
  .first-foot {
    h6 {
      text-shadow: rgb(78 190 147) 1px 1px 7px;
      color: #0b100e;
      font-weight: 700;
    }
  }
  .footer-body {
    background: linear-gradient(358deg, #43c6ac, #f8f8f9);
  }
  .socialImagenav {
    height: 2rem;
  }

  .ft-logo {
    img {
      height: 15rem;
      margin-top: -6rem;
    }
  }

  .footdetails {
    width: 70%;
  }

  ul {
    li {
      list-style: none;
    }
  }

  .socialcontact {
    margin-left: -3rem;
  }
`;
