import React from "react";
import styled from "styled-components";
import Layout from "../Layout/Layout";
import HeroSection from "../component/HeroSection";
import IpoPremium from "../component/IpoPremium";
import GrayMarketHome from "../component/GrayMarketHome";
import IpoDocument from "../component/IpoDocument";
import UpcomingIpoMainline from "../component/UpcomingIpoMainline";
import HeroUpdate from "../component/HeroUpdate";
import Resentmain from "../component/Resentmain";

const Home = () => {
  return (
    <>
      <Container>
        <Layout title={"Home - upcoming ipo 2024"}>
          <Resentmain />
          {/* <HeroSection /> */}
          {/* <HeroUpdate /> */}
          {/* <GrayMarketHome /> */}
          <hr />
          {/* <IpoDocument /> */}
          <hr />
          {/* <UpcomingIpoMainline /> */}
        </Layout>
      </Container>
    </>
  );
};

export default Home;
const Container = styled.div`
  // background: linear-gradient(to bottom, #e1eec3, #d2edff);
`;
