import React from "react";
import styled from "styled-components";

const Resentmain = () => {
  return (
    <>
      <Container>
        <div className="container iponame px-5">
          <div className="row gx-5 px-5">
            <div className="col px-5">
              <b className="date justify-content-center">13 Dec-15 dec</b>
              <div className="p-3 border ipo1 fnames ms-4">IPO Name</div>
              <div className="border-start sidebar1 mt-4 px-3 ms-4">
                <div
                  className="p-3 border names ms-1"
                  style={{ width: "423px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-1"
                  style={{ width: "423px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-1"
                  style={{ width: "423px" }}
                ></div>
              </div>
              <b className="date justify-content-center pt-3">13 Dec-15 dec</b>
              <div className="p-3 border fnames ipo3 ms-4">IPO Name</div>
              <div className="border-start sidebar3 mt-4 px-3 ms-4">
                <div
                  className="p-3 border names ms-1"
                  style={{ width: "423px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-1"
                  style={{ width: "423px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-1"
                  style={{ width: "423px" }}
                ></div>
              </div>
            </div>
            <div className="col column px-5">
              <b className="date dates1 justify-content-center">
                13 Dec-15 dec
              </b>
              <div className="p-3 border ipo2 fnames px-3 ms-4 ">IPO Name</div>
              <div className="border-start sidebar2 mt-4 px-3 ms-4">
                <div
                  className="p-3 border names ms-1 moneipo"
                  style={{ width: "423px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-1 moneipo"
                  style={{ width: "423px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-1 moneipo"
                  style={{ width: "423px" }}
                ></div>
              </div>
              <b className="date dates4 justify-content-center pt-3">
                13 Dec-15 dec
              </b>
              <div className="p-3 border ipo4 fnames px-3 ms-4">IPO Name</div>
              <div className="border-start sidebar4 mt-4 px-3 ms-4">
                <div
                  className="p-3 border names ms-1"
                  style={{ width: "423px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-1"
                  style={{ width: "423px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-1"
                  style={{ width: "423px" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container resentlywork">
          <div className="justify-content-center trcentmains pt-4">
            Recent Main IPOs
          </div>
          <div className="row row-cols-4 row-cols-lg-4 g-2 g-lg-3 pt-3">
            <div className="col">
              <div className="p-3 border Rowcolumn iposmain1"></div>
            </div>
            <div className="col">
              <div className="p-3 border Rowcolumn iposmain2"></div>
            </div>
            <div className="col">
              <div className="p-3 border Rowcolumn iposmain3"></div>
            </div>
            <div className="col">
              <div className="p-3 border Rowcolumn iposmain4"></div>
            </div>
          </div>
          <b className="andmore me-5 pt-2">& more</b>
        </div>
      </Container>
    </>
  );
};

export default Resentmain;
const Container = styled.div`
  .date {
    display: flex;
    align-items: center;
    font-size: 25px;
  }
  .fnames {
    width: 448px;
  }
  .container .row .col .ipo1 {
    background-color: #d0bcff !important;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 21px;
  }
  .container .row .col .ipo2 {
    background-color: #ffafe9 !important;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 21px;
  }
  .container .row .col .ipo3 {
    background-color: #a1ccf1e3 !important;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 21px;
  }
  .container .row .col .ipo4 {
    background-color: #ffc5b3 !important;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 21px;
  }
  .border-start {
    height: 133px;
    border-radius: 10px;
    width: 20px;
  }
  .container .row .col .sidebar1 {
    border-left: 11px solid #d0bcff !important;
  }
  .container .row .col .sidebar2 {
    border-left: 11px solid #ffafe9 !important;
  }
  .container .row .col .sidebar3 {
    border-left: 11px solid #a1ccf1e3 !important;
  }
  .container .row .col .sidebar4 {
    border-left: 11px solid #ffc5b3 !important;
  }
  .names {
    background-color: rgb(255 254 220) !important;
    border-radius: 9px;
  }
  .trcentmains {
    display: flex;
    font-weight: 400;
    font-size: 29px;
  }
  .Rowcolumn {
    height: 224px;
    border-radius: 35px;
    width: 296px;
  }
  .iposmain1 {
    background: #c7e8db;
  }
  .iposmain2 {
    background-color: #d0bcff !important;
  }
  .iposmain3 {
    background-color: #a1ccf1e3 !important;
  }
  .iposmain4 {
    background-color: #ffafe9 !important;
  }
  .andmore {
    display: flex;
    flex-direction: row-reverse;
  }
  @media only screen and (max-width: 285px) and (min-width: 200px) {
    .iponame {
      padding-left: 0rem !important;
    }
    .container .row .col .ipo1 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo2 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo3 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo4 {
      margin-left: -67px !important;
    }
    .border-start {
      margin-left: -65px !important;
    }
    .names {
      width: 412px !important;
    }
    .Rowcolumn {
      height: 109px !important;
      width: 105px !important;
      margin-left: -10px;
    }
    .iposmain2 {
      margin-left: 36px !important;
    }
    .iposmain3 {
      margin-left: 84px !important;
    }
    .iposmain4 {
      margin-left: 134px !important;
    }
    .date {
      width: 298px;
    }
  }
  @media only screen and (max-width: 365px) and (min-width: 285px) {
    .iponame {
      padding-left: 0rem !important;
    }
    .container .row .col .ipo1 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo2 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo3 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo4 {
      margin-left: -67px !important;
    }
    .border-start {
      margin-left: -65px !important;
    }
    .names {
      width: 412px !important;
    }
    .Rowcolumn {
      height: 109px !important;
      width: 105px !important;
      margin-left: -10px;
    }
    .iposmain2 {
      margin-left: 16px !important;
    }
    .iposmain3 {
      margin-left: 40px !important;
    }
    .iposmain4 {
      margin-left: 64px !important;
    }
  }
  @media only screen and (max-width: 380px) and (min-width: 365px) {
    .iponame {
      padding-left: 0rem !important;
    }
    .container .row .col .ipo1 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo2 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo3 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo4 {
      margin-left: -67px !important;
    }
    .border-start {
      margin-left: -65px !important;
    }
    .names {
      width: 412px !important;
    }
    .Rowcolumn {
      height: 109px !important;
      width: 105px !important;
      margin-left: -10px;
    }
    .iposmain2 {
      margin-left: 16px !important;
    }
    .iposmain3 {
      margin-left: 40px !important;
    }
    .iposmain4 {
      margin-left: 64px !important;
    }
  }
  @media only screen and (max-width: 395px) and (min-width: 380px) {
    .iponame {
      padding-left: 0rem !important;
    }
    .container .row .col .ipo1 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo2 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo3 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo4 {
      margin-left: -67px !important;
    }
    .border-start {
      margin-left: -65px !important;
    }
    .names {
      width: 412px !important;
    }
    .Rowcolumn {
      height: 109px !important;
      width: 105px !important;
      margin-left: -10px;
    }
    .iposmain2 {
      margin-left: 16px !important;
    }
    .iposmain3 {
      margin-left: 40px !important;
    }
    .iposmain4 {
      margin-left: 64px !important;
    }
  }
  @media only screen and (max-width: 419px) and (min-width: 395px) {
    .iponame {
      padding-left: 0rem !important;
    }
    .container .row .col .ipo1 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo2 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo3 {
      margin-left: -67px !important;
    }
    .container .row .col .ipo4 {
      margin-left: -67px !important;
    }
    .border-start {
      margin-left: -65px !important;
    }
    .names {
      width: 412px !important;
    }
    .Rowcolumn {
      height: 109px !important;
      width: 105px !important;
      margin-left: -10px;
    }
    .iposmain2 {
      margin-left: 16px !important;
    }
    .iposmain3 {
      margin-left: 40px !important;
    }
    .iposmain4 {
      margin-left: 64px !important;
    }
    .resentlywork {
      height: 452px !important;
    }
  }
  @media only screen and (max-width: 545px) and (min-width: 419px) {
    .resentlywork {
      height: 341px !important;
    }
    .Rowcolumn {
      height: 126px;
      width: 126px;
    }
  }
  @media only screen and (max-width: 773px) and (min-width: 545px) {
    .Rowcolumn {
      height: 141px;
      width: 152px;
    }
    ${
      "" /* .resentlywork{
    height: 601px;
} */
    }
  }
  @media only screen and (max-width: 825px) and (min-width: 773px) {
    .resentlywork {
      height: 841px !important;
    }
    .Rowcolumn {
      height: 160px;
      width: 166px;
    }
  }
  @media only screen and (max-width: 917px) and (min-width: 825px) {
    .resentlywork {
      height: 1020px !important;
    }
    .Rowcolumn {
      height: 168px;
      width: 169px;
    }
  }
  @media only screen and (max-width: 1029px) and (min-width: 917px) {
    .Rowcolumn {
      height: 167px;
      width: 179px;
    }
    .iposmain2 {
      margin-left: -37px;
    }
    .iposmain3 {
      margin-left: -70px;
    }
    .iposmain4 {
      margin-left: -100px;
    }
    .fnames {
      margin-left: 220px !important;
    }
    .border-start {
      margin-left: 218px !important;
    }
  }
  @media only screen and (max-width: 1285px) and (min-width: 1029px) {
    .Rowcolumn {
      height: 206px;
      width: 228px;
    }
    .iposmain2 {
      margin-left: -37px;
    }
    .iposmain3 {
      margin-left: -70px;
    }
    .iposmain4 {
      margin-left: -100px;
    }
    .fnames {
      margin-left: 220px !important;
    }
    .border-start {
      margin-left: 218px !important;
    }
    .resentlywork {
      height: 452px !important;
    }
  }
`;
