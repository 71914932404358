import React from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

const HeroSection = () => {
  return (
    <>
      <Container>
        <div className="cont-box  mt-5">
          <div className="main-div">
            <div className="container">
              <div className="row g-5">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="container table-left">
                    <h2 id="title">13 Dec - 15 Dec</h2>
                    {/* <table className="employee">
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Open</th>
                          <th>Close</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                      </tbody>
                    </table> */}
                    <h3 style={{ backgroundColor: "#d1bcff" }}>IPO Name</h3>
                    {/* <div>
                      <button className="btn btn-success mt-3">
                        More Information
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  {" "}
                  <div className="container table-left">
                    <h2 id="title">13 Dec - 15 Dec</h2>
                    {/* <table className="employee">
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Open</th>
                          <th>Close</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                      </tbody>
                    </table> */}
                    <h3 style={{ backgroundColor: "#ffafe9" }}>IPO Name</h3>
                    {/* <div>
                      <button className="btn btn-success mt-3">
                        More Information
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="container table-left">
                    <h2 id="title">13 Dec - 15 Dec</h2>
                    {/* <table className="employee">
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Open</th>
                          <th>Close</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                      </tbody>
                    </table> */}
                    <h3 style={{ backgroundColor: "#abd2f3" }}>IPO Name</h3>
                    {/* <div>
                      <button className="btn btn-success mt-3">
                        More Information
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="container table-left">
                    <h2 id="title">13 Dec - 15 Dec</h2>
                    {/* <table className="employee">
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Open</th>
                          <th>Close</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                        <tr>
                          <td>RBZ Jewellers</td>
                          <td>TBA</td>
                          <td>TBA</td>
                        </tr>
                      </tbody>
                    </table> */}
                    <h3 style={{ backgroundColor: "#ffc5b3" }}>IPO Name</h3>
                    {/* <div>
                      <button className="btn btn-success mt-3">
                        More Information
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HeroSection;

const Container = styled.div`
  .main-div {
    height: 100%;
    // background-color: #d4dcdc;
  }
  h2 {
    // text-shadow: 1px 9px 14px #0b0b0b;
    color: #4b4333;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 2px solid #000;
  }

  thead {
    background-color: #55efc4;
    color: black;
    text-align: center;
    padding: 10px;
  }

  tbody,
  thead {
    border: 1px solid #ddd;
    padding: 12px;
  }

  tr:hover {
    background-color: #000;
    color: white;
  }

  td {
    border: 1px solid #000;
    padding: 10px;
    color: white;
  }
  th {
    border: 1px solid #000;
    padding: 10px;
  }

  .table-left {
    // box-shadow: 1px 6px 20px #3736368c;
    padding: 2rem;
    border-radius: 1rem;
    // background-color: #00b894;
  }

  .employee tbody tr:nth-child(odd) {
    background-color: #00b894;
  }

  .employee tbody tr:nth-child(even) {
    background-color: #0f6c59;
  }

  h3 {
    padding: 0.5rem;
    border-radius: 0.8rem;
  }
`;
