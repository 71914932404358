import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <Container>
        <nav class="navbar navbar-expand-lg navbar-light bg-white naxbar">
          <div class="container-fluid">
            <Link>
              <img
                src="https://res.cloudinary.com/antrix/image/upload/v1701974425/ipo/ipomoney_logo_xr5reo.png"
                class="navbar-brand"
                alt="logo"
              />
            </Link>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                <li class="nav-item border-right btn btn-primary shadow">
                  <Link class="nav-link" aria-current="page" to="/">
                    Home
                  </Link>
                </li>

                <li class="nav-item dropdown border-right btn btn-primary shadow">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="navbarDropdown"
                    role="button"
                    target="_blank"
                    rel="noreferrer"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    IPO News
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li class="nav-item">
                      <Link class="nav-link" to="/ipo-gmp">
                        IPO GMP
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/ipo-allot-status">
                        IPO Allotment
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/apply-IPO">
                        Apply IPO
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/ipo-listing">
                        IPO listing
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/ipo-listing">
                        Learn IPO
                      </Link>
                    </li>
                  </ul>
                </li>
                <li class="nav-item border-right btn btn-primary shadow">
                  <Link class="nav-link" to="/main-IPO">
                    Main IPO
                  </Link>
                </li>
                <li class="nav-item border-right btn btn-primary shadow">
                  <Link class="nav-link" to="/ipo-sme">
                    SME IPO
                  </Link>
                </li>
                <li class="nav-item btn btn-primary shadow">
                  <Link class="nav-link" to="/ipo-subscription-status">
                    IPO Subscription
                  </Link>
                </li>
                <li class="nav-item btn btn-primary shadow">
                  <Link class="nav-link" to="/latest-ipo">
                    Latest IPO Update
                  </Link>
                </li>
                <li class="nav-item btn btn-primary shadow">
                  <Link class="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li class="nav-item btn btn-primary shadow">
                  <Link class="nav-link" to="/career">
                    Career
                  </Link>
                </li>
              </ul>
              <Link to="/contact">
                <button className="btn btn-success fs-3">Contact us</button>
              </Link>
            </div>
          </div>
        </nav>
      </Container>
    </>
  );
};

export default Header;
const Container = styled.div`
  .naxbar {
    // background-color: #d4dcdc !important;
    padding: 1rem 1rem;
    // box-shadow: 0px 0px 11px #c4b8b8;
    // box-shadow: 1px 1px 10px black;
    img {
      height: 7rem;
      width: 11rem;
    }
    li {
      font-size: 1.5rem;
      margin-right: 1rem;
      padding: 0;
      font-weight: bold;
      font-family: "Bricolage Grotesque", sans-serif;
      color: #fdcb6e;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      .nav-link {
        color: #fdcb6e;
        font-size: 1rem;
        text-shadow: 1px 6px 14px #3a3838;
      }
    }
    button {
      font-family: "Bricolage Grotesque", sans-serif;
      background-color: #f53237;
      border: none;
    }
  }

  .dropdown-menu {
    background-color: #1763d2;
  }

  // .border-right {
  //   border-right: 1px solid #d63031;
  // }
`;
