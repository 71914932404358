import React from "react";
import styled from "styled-components";

const IpoPremium = () => {
  return (
    <>
      <Container>
        <div className="container-fluid hght">
          <h1>Premium</h1>
        </div>
      </Container>
    </>
  );
};

export default IpoPremium;
const Container = styled.div`
  .hght {
    height: 20rem;
  }
`;
