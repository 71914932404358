import React from "react";
import styled from "styled-components";

const UpcomingIpoMainline = () => {
  return (
    <>
      <Container>
        <div className="container">
          <h2>IPO Grey Market Premium</h2>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <div class="card shadow rounded">
                <img
                  src="https://res.cloudinary.com/antrix/image/upload/v1695997775/bigbulls/bigbull_about11_m0opcq.png"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <p class="card-text">
                    India Shelter Finance IPO GMP, Grey Market Premium Today
                  </p>
                  <small>07 Dec, 2023</small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <div class="card shadow rounded">
                <img
                  src="https://res.cloudinary.com/antrix/image/upload/v1695997775/bigbulls/bigbull_about11_m0opcq.png"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <p class="card-text">
                    India Shelter Finance IPO GMP, Grey Market Premium Today
                  </p>
                  <small>07 Dec, 2023</small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <div class="card shadow rounded">
                <img
                  src="https://res.cloudinary.com/antrix/image/upload/v1695997775/bigbulls/bigbull_about11_m0opcq.png"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <p class="card-text">
                    India Shelter Finance IPO GMP, Grey Market Premium Today
                  </p>
                  <small>07 Dec, 2023</small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <div class="card shadow rounded">
                <img
                  src="https://res.cloudinary.com/antrix/image/upload/v1695997775/bigbulls/bigbull_about11_m0opcq.png"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <p class="card-text">
                    India Shelter Finance IPO GMP, Grey Market Premium Today
                  </p>
                  <small>07 Dec, 2023</small>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button className="btn btn-success mt-3">More Information</button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default UpcomingIpoMainline;
const Container = styled.div`
  h2 {
    text-shadow: 1px 9px 14px #0b0b0b;
    color: #000;
    margin-bottom: 2rem;
  }
  small {
    color: grey;
  }
`;
