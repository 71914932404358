import React from "react";
import styled from "styled-components";

const IpoDocument = () => {
  return (
    <>
      <Container>
        <div className="main-div">
          <div className="container">
            <div className="row pt-5">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="container table-left">
                  <h2 id="title">SME IPO Prospectus Document (DRHP, RHP)</h2>
                  <table className="employee">
                    <thead>
                      <tr>
                        <th>Company</th>
                        <th>Open</th>
                        <th>Close</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <button className="btn btn-success mt-3">
                      More Information
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                {" "}
                <div className="container table-left">
                  <h2 id="title">SME IPO Basis of Allotment</h2>
                  <table className="employee">
                    <thead>
                      <tr>
                        <th>Company</th>
                        <th>Open</th>
                        <th>Close</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                      <tr>
                        <td>RBZ Jewellers</td>
                        <td>TBA</td>
                        <td>TBA</td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <button className="btn btn-success mt-3">
                      More Information
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default IpoDocument;
const Container = styled.div`
  h2 {
    text-shadow: 1px 9px 14px #0b0b0b;
    color: #000;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 2px solid #000;
  }

  thead {
    background-color: #55efc4;
    color: black;
    text-align: center;
    padding: 10px;
  }

  tbody,
  thead {
    border: 1px solid #ddd;
    padding: 12px;
  }

  tr:hover {
    background-color: #000;
    color: white;
  }

  td {
    border: 1px solid #000;
    padding: 10px;
  }
  th {
    border: 1px solid #000;
    padding: 10px;
  }

  .table-left {
    box-shadow: 1px 6px 20px #3736368c;
    padding: 2rem;
    border-radius: 1rem;
  }
`;
