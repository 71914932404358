import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import IpoGmp from "./pages/IpoGmp";
import UpcomingIpo from "./pages/UpcomingIpo";
import SmeIpo from "./pages/SmeIpo";
import IpoAllotStatus from "./pages/IpoAllotStatus";
import IpoSubsStatus from "./pages/IpoSubsStatus";
import Contact from "./pages/Contact";
import IpoForms from "./pages/IpoForms";
import IpoListing from "./pages/IpoListing";
import NcdInfo from "./pages/NcdInfo";
import Faq from "./pages/Faq";
import ApplyIpo from "./pages/ApplyIpo";
import LearnIpo from "./pages/LearnIpo";
import MainIpo from "./pages/MainIpo";
import LatestIpo from "./pages/LatestIpo";
import About from "./pages/About";
import Career from "./pages/Career";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ipo-gmp" element={<IpoGmp />} />
        <Route path="/ipo-upcoming" element={<UpcomingIpo />} />
        <Route path="/ipo-sme" element={<SmeIpo />} />
        <Route path="/ipo-allot-status" element={<IpoAllotStatus />} />
        <Route path="/apply-IPO" element={<ApplyIpo />} />
        <Route path="/ipo-subscription-status" element={<IpoSubsStatus />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ipo-forms" element={<IpoForms />} />
        <Route path="/ipo-listing" element={<IpoListing />} />
        <Route path="/learn-IPO" element={<LearnIpo />} />
        <Route path="/main-IPO" element={<MainIpo />} />
        <Route path="/ncd-information" element={<NcdInfo />} />
        <Route path="/latest-ipo" element={<LatestIpo />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<About />} />
        <Route path="/career" element={<Career />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default App;
