import React from "react";
import styled from "styled-components";

const HeroUpdate = () => {
  return (
    <>
      <Container>
        <div className="container iponame px-4 pt-4">
          <div className="row gx-5">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <b className="date">13 Dec-15 dec</b>
              <div className="p-3 border ipo1 fnames bg-light">IPO Name</div>
              <div className="border-start sidebar1 mt-4">
                {/* <div className="border circle1 ms-2"></div> */}
                <div
                  className="p-3 border names ms-4"
                  style={{ width: "432px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-4"
                  style={{ width: "432px" }}
                ></div>
                <div
                  className="p-3 mt-3 border names ms-4"
                  style={{ width: "432px" }}
                ></div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <b className="date dates1">13 Dec-15 dec</b>
              <div class="p-3 border ipo2 fnames">IPO Name</div>
              <div class="border-start sidebar2 mt-4">
                <div
                  class="p-3 border names ms-4 moneipo"
                  style={{ width: "432px" }}
                ></div>
                <div
                  class="p-3 mt-3 border names ms-4 moneipo"
                  style={{ width: "432px" }}
                ></div>
                <div
                  class="p-3 mt-3 border names ms-4 moneipo"
                  style={{ width: "432px" }}
                ></div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <b className="date">13 Dec-15 dec</b>
              <div class="p-3 border fnames ipo3 bg-light">IPO Name</div>
              <div class="border-start sidebar3 mt-4">
                <div
                  class="p-3 border names ms-4"
                  style={{ width: "432px" }}
                ></div>
                <div
                  class="p-3 mt-3 border names ms-4"
                  style={{ width: "432px" }}
                ></div>
                <div
                  class="p-3 mt-3 border names ms-4"
                  style={{ width: "432px" }}
                ></div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <b className="date dates4">13 Dec-15 dec</b>
              <div class="p-3 border ipo4 fnames bg-light">IPO Name</div>
              <div class="border-start sidebar4 mt-4">
                <div
                  class="p-3 border names ms-4"
                  style={{ width: "432px" }}
                ></div>
                <div
                  class="p-3 mt-3 border names ms-4"
                  style={{ width: "432px" }}
                ></div>
                <div
                  class="p-3 mt-3 border names ms-4"
                  style={{ width: "432px" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HeroUpdate;
const Container = styled.div``;
